import { getPageHtml, getPageProps } from '../util/PageUtil';

function IndexPage(props) {
  return getPageHtml(props);
}

// This gets called on every request
export async function getServerSideProps(context) {
  return getPageProps(context);
}

export default IndexPage;
